@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
a {
  transition: 1s;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 2em;
  letter-spacing: 0.015em;
  background: url(../images/common/bg.png) center top;
  color: #333333;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  word-wrap: break-word;
}

figure {
  padding: 0;
  margin: 0;
}

.inner {
  margin: 0 auto;
  max-width: 960px;
}

@media screen and (max-width: 640px) {
  .inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.sinner {
  max-width: 620px;
}

.bornone td {
  border: none !important;
}

.pdnone td {
  padding: 0 !important;
}

.img100 img {
  width: 100%;
}

.link1 a {
  color: #558c00;
  font-weight: bold;
}

#contents_wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

.policy h2 {
  border-bottom: 1px solid #999;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.policy p {
  margin: 0 0 20px;
}

table td {
  margin-bottom: -1px !important;
  padding: 20px !important;
}

.tlauto table {
  table-layout: auto;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

u {
  text-decoration: underline;
}

.borB {
  border-bottom: 1px dashed #333;
}

.b {
  font-weight: bold;
}

b {
  font-weight: bold;
}

.borderB {
  border-bottom: 1px dashed #333;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a:hover {
  opacity: 0.6;
}

#contents_wrap {
  min-width: auto;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 640px) {
  #contents_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#contents {
  max-width: 960px;
  width: 100%;
}

.tacenter {
  text-align: center !important;
}

.ColLeft {
  float: left;
}

.ColRight {
  float: right;
}

.flexleft {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
}

.flexcenter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.fwb {
  font-weight: bold;
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 768px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
}

header#global_header {
  max-width: 100%;
}

#header {
  width: 100%;
  padding: 25px 0;
}

@media screen and (max-width: 768px) {
  #header {
    padding: 70px 0 20px;
  }
}

#header .inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #header .inner {
    display: block;
  }
}

#header .inner .box1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  max-width: 415px;
}

@media screen and (max-width: 768px) {
  #header .inner .box1 {
    margin: 15px auto 0;
  }
}

#header .inner .box1 .hed_tel {
  margin-right: 10px;
}

#nav_global {
  background: #f7f7f7;
}

#nav_global ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
}

#nav_global ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

#nav_global ul li:last-child a span {
  border-right: none;
}

#nav_global ul li a {
  display: block;
  text-decoration: none;
  padding: 25px 0;
  font-size: 14px;
}

#nav_global ul li a span {
  display: block;
  border-right: 1px solid #ccc;
}

#main_image img {
  width: 100%;
}

.mean-container .mean-bar {
  background: #558c00 !important;
  position: fixed !important;
}

.mean-container .mean-nav {
  background: #558c00 !important;
}

#mainArea {
  position: relative;
}

#local-keyvisual {
  position: relative;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 768px) {
  #local-keyvisual {
    margin-top: 0;
    margin-bottom: 0px;
  }
}

#local-keyvisual #local-keyvisual-title {
  position: relative;
  width: 84.91%;
  margin: 0 auto;
  padding-top: 80px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title {
    height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  #local-keyvisual #local-keyvisual-title {
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }
}

#local-keyvisual .ttlh1 {
  background: url(../images/common/main_kasou.jpg) left center;
  position: relative;
  text-align: center;
  padding: 10px;
  height: 400px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual .ttlh1 {
    height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  #local-keyvisual .ttlh1 {
    height: auto;
  }
}

#local-keyvisual .ttlh1 h1 {
  border: double #fff;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.7em;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-size: 3.125vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual .ttlh1 h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  #local-keyvisual .ttlh1 h1 {
    font-size: 45px;
    padding: 100px 0;
    margin: 20px;
  }
}

@media screen and (max-width: 640px) {
  #local-keyvisual .ttlh1 h1 {
    padding: 50px 0;
    font-size: 35px;
    line-height: 1.7em;
    margin: 10px;
  }
}

#local-keyvisual .ttlh1 h1 span {
  display: block;
}

#local-keyvisual .ttlh1 h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg {
  width: 100%;
  height: 410px;
  background: url(../images/common/bg-green-pattern.png) left top;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-bg {
    height: 310px;
  }
}

@media only screen and (max-width: 768px) {
  #local-keyvisual #local-keyvisual-bg {
    display: none;
  }
}

#footer .nav {
  padding: 20px;
}

#footer .nav a {
  text-decoration: none;
}

#footer .copy {
  font-size: 13px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  background: #558c00;
}

#contents_wrap {
  padding: 0 0 100px;
}

@media screen and (max-width: 768px) {
  #contents_wrap {
    padding: 0 10px 80px;
  }
}

@media screen and (max-width: 640px) {
  #contents_wrap {
    padding: 0 10px 50px;
  }
}

/* TOP */
.cont_1 {
  line-height: 2.2em;
}

.news {
  padding: 30px;
  border: 1px solid #ccc;
}

.news .scroll {
  margin-top: 20px;
  max-height: 250px;
  overflow-y: scroll;
  padding-right: 10px;
}

.news .scroll dl {
  display: block !important;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 15px;
}

.news .scroll dl:last-of-type dt {
  border-bottom: 0 !important;
}

.news .scroll dl:last-of-type dd {
  border-bottom: 0 !important;
}

.news .scroll dl dt {
  width: 100% !important;
  border: 0;
  background: none;
  padding: 0;
}

.news .scroll dl dd {
  width: 100% !important;
  border: 0;
  padding: 0;
}

/* 3列 */
.wrap1 {
  margin-left: -3.0303% !important;
}

@media screen and (max-width: 640px) {
  .wrap1 {
    margin-left: 0% !important;
  }
}

.wrap1 .box1 {
  float: left !important;
  width: 30.30303% !important;
  margin-left: 3.0303% !important;
}

@media screen and (max-width: 640px) {
  .wrap1 .box1 {
    margin-left: 0 !important;
  }
}

#disnone {
  display: none;
}

/* title */
.h1wrap h1 {
  background: #558c00;
  color: #fff;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .h1wrap h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .h1wrap h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .h1wrap h1 {
    font-size: 20px;
  }
}

.midashi h1 {
  position: relative;
  background: #f56c22;
  box-shadow: 0px 0px 0px 5px #f56c22;
  border: dashed 2px white;
  padding: 0.5em;
  color: #fff;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .midashi h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi h1 {
    font-size: 20px;
  }
}

.midashi h1:after {
  position: absolute;
  content: '';
  left: -7px;
  top: -7px;
  border-width: 0 0 15px 15px;
  border-style: solid;
  border-color: #fff #fff #f56c22;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.midashi2 {
  margin: 0 0 30px 0;
}

.midashi2 h2 {
  padding-bottom: 0px;
  font-weight: bold;
  border-bottom: 2px dotted #999;
  background: url(../images/common/title_icon.png) left center no-repeat;
  padding-left: 40px;
  line-height: 2em;
  font-size: 27px;
  font-size: 1.6875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 27px;
  }
}

@media screen and (max-width: 768px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 18px;
  }
}

.緑 {
  margin: 0 0 30px 0;
}

.緑 h2 {
  position: relative;
  background: #96c62f;
  box-shadow: 0px 0px 0px 5px #96c62f;
  border: dashed 2px white;
  padding: 0.5em;
  color: #fff;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .緑 h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .緑 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .緑 h2 {
    font-size: 18px;
  }
}

.緑 h2:after {
  position: absolute;
  content: '';
  left: -7px;
  top: -7px;
  border-width: 0 0 15px 15px;
  border-style: solid;
  border-color: #fff #fff #96c62f;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.オレンジ {
  margin: 0 0 30px 0;
}

.オレンジ h2 {
  position: relative;
  background: #f56c22;
  box-shadow: 0px 0px 0px 5px #f56c22;
  border: dashed 2px white;
  padding: 0.5em;
  color: #fff;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .オレンジ h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .オレンジ h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .オレンジ h2 {
    font-size: 18px;
  }
}

.オレンジ h2:after {
  position: absolute;
  content: '';
  left: -7px;
  top: -7px;
  border-width: 0 0 15px 15px;
  border-style: solid;
  border-color: #fff #fff #f56c22;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.midashi3 {
  margin: 0 0 30px 0;
}

.midashi3 h3 {
  padding-bottom: 0px;
  font-weight: bold;
  border-bottom: 2px dotted #999;
  background: url(../images/common/title_icon.png) left center no-repeat;
  padding-left: 40px;
  line-height: 2em;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .midashi3 h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .midashi3 h3 {
    font-size: 18px;
  }
}

h2.h2title2 {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #999;
  line-height: 1.6;
  padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
  h2.h2title2 {
    font-size: 14px;
    font-size: 0.875vw;
  }
}

/* common */
.cont_2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .cont_2 {
    display: block;
  }
}

.cont_2 a {
  width: 38% !important;
}

@media screen and (max-width: 640px) {
  .cont_2 a {
    width: 100% !important;
  }
}

.cont_2 > div {
  width: 57%;
}

@media screen and (max-width: 640px) {
  .cont_2 > div {
    width: 100% !important;
  }
}

.cont_3 span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cont_3 span b {
  margin-right: 15px;
  color: #fff;
  background: red;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pan {
  margin: 10px 0 30px 0 !important;
}

.pan a {
  color: #558c00;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .td100 td {
    width: 100% !important;
    display: block;
  }
}

.clearnone {
  clear: none !important;
}

.banner_wrap .box1 {
  float: left;
  clear: none !important;
  margin-right: -1px;
  width: 25% !important;
}

@media screen and (max-width: 768px) {
  .banner_wrap .box1 {
    width: 50% !important;
  }
}

.banner_wrap .box1 img {
  width: 100% !important;
  max-width: 100% !important;
  margin-right: 0 !important;
  margin-bottom: -2px;
}

.banner_txt1 a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  width: 48%;
  text-align: center;
  padding: 20px 0;
  border-radius: 30px;
}

.banner_txt1 a i {
  margin-right: 15px;
}

.banner_txt1 .btn1 {
  background: #558c00;
}

.banner_txt1 .btn2 {
  float: right;
  background: #f56c22;
}

.faq_wrap td {
  vertical-align: top !important;
}

.faq_wrap .box1 {
  text-align: left !important;
  width: 70px !important;
}

.faq_wrap .box2 {
  vertical-align: middle !important;
}

.faq_wrap .box2 b {
  color: #558c00;
  font-size: 16px;
}

.faq_wrap .box_img {
  width: 20% !important;
  padding-left: 10px !important;
}

@media screen and (max-width: 640px) {
  .faq_wrap .box_img {
    display: none !important;
  }
}

.pdf_txt a img {
  max-width: 107px;
  margin-top: 10px;
}

.voice_wrap {
  border: 1px solid #ccc;
  padding: 20px;
}

.voice_wrap h3 {
  border-bottom: 1px dotted #ccc;
  list-style: 1.6;
  color: #558c00;
  font-size: 16px;
  padding-bottom: 5px;
}

.taio_wrap {
  border: 1px solid #ccc;
  padding: 20px;
}

.taio_wrap h3 {
  background: #558c00;
  color: #fff;
  line-height: 1.6;
  padding: 10px;
  font-weight: bold;
  border-radius: 20px;
}

.taio_wrap span {
  display: block;
  background: #f6f6f6;
  padding: 20px;
  border: 1px solid #ccc;
}

.pdtop {
  padding-top: 100px;
  margin-top: -100px;
}
